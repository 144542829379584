.section {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

.header {
  border-bottom: 1px solid #e8e8e8;
  padding: 8px;
}

.description {
  padding: 8px;
  margin: 0;
}
