.label {
  margin-bottom: 8px;

  color: rgba(0, 0, 0, 0.45);
}

.buttonTitle {
  margin-top: 8px;
}

.image {
  width: 100%;
}
