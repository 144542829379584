.title {
  margin-bottom: 16px;

  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}

.row {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  margin-bottom: 16px;
}

.row:last-child {
  margin-bottom: 0;
}

.item {
  display: flex;
  width: 20%;
  flex: 1 1 auto;
}

.label {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  text-align: start;
  font-size: 14px;
  line-height: 1.5;
}

.value {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5;
}

