.searchInput {
  width: 50%;
  min-width: 300px;
}

.searchInput label {
  display: block;
  padding-bottom: 2px;

  line-height: 1.571;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
