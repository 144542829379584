.wrapper {
  display: flex;
  padding: 16px;
}

.column {
  flex-direction: column;
}

.column.noStart {
  padding-block-start: 0;
}

.column.noEnd {
  padding-block-end: 0;
}

.row {
  flex-direction: row;
  justify-content: space-between;
}

.row.noStart {
  padding-inline-start: 0;
}

.row.noEnd {
  padding-inline-end: 0;
}

.topBorder {
  border-top: thin solid #eee;
}

.bottomBorder {
  border-bottom: thin solid #eee;
}
