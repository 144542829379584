.configuration {
  padding-block-end: 16px;
}

.configuration__header {
  margin-bottom: 16px;
}

.configuration__body {
  border: '1px solid #eee';
  border-radius: 8px;
  overflow: 'hidden';
}