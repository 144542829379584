.tags_create_header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.tags_create_content {
  padding: 16px;
}
