.section {
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

.header {
  border-bottom: 1px solid #e8e8e8;
  padding: 8px;
}

.table {
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.table
  :global(.ant-table)
  :global(.ant-table-row:last-child)
  :global(.ant-table-cell) {
  border-bottom: none;
}
