.dashboard__gap {
  margin-block: 32px;
}

.dashboard__stats_title {
  display: flex;
  gap: 16px;
}

.dashboard__stats_grid {
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
}

.dashboard__datepicker {
  flex: 1;
  display: flex;
  gap: 4px;
  align-items: center;
}

.dashboard__date_options {
  margin-bottom: 8px;
}

.dashboard__date_options :global(.ant-radio-button-wrapper) {
  margin-right: 8px;
  border-radius: 6px;
  margin-bottom: 8px;

  border-left-width: 1px;
}

.dashboard__date_options :global(.ant-radio-button-wrapper::before) {
  display: none;
}

.dashboard__date_options :global(.ant-picker) {
  margin-bottom: 8px;
}
