.row {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.row:last-child {
  margin-bottom: 0;
}

.tag {
  padding: 5px 8px;
  font-size: 14px;

  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.tag:global(.ant-tag-checkable-checked) {
  border-color: transparent;
}

.amountForm {
  display: flex;
  gap: 8px;
}

.input {
  margin-bottom: 0;
}

.select {
  width: 250px;
  margin-bottom: 12px;
}

.input :global(.ant-form-item-label label) {
  color: rgba(0, 0, 0, 0.45);
}

.input :global(.ant-form-item-explain-error) {
  position: absolute;
  white-space: nowrap;
}

.input :global(.ant-form-item-label) {
  padding-bottom: 2px !important;
}

.dateInput {
  margin-right: 8px;
}

.dateInput label {
  display: block;
  padding-bottom: 2px;

  line-height: 1.571;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
