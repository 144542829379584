.restriction {
  display: grid;
  grid-template-columns: minmax(6rem, auto) 1fr;
  grid-gap: 0 0.5rem;
  margin: 0;
}

.restriction dd {
  margin: 0;
}
