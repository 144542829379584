.stat_card {
  border-radius: 0;
}

.stat_card :global(.ant-card-head-title) {
  padding-block: 16px;
  font-size: 1rem;
}

.stat_card :global(.ant-card-body) {
  padding-block: 16px;
  font-size: 2rem;
  text-align: center;
}

.stat_card:global(.ant-card-small) :global(.ant-card-head-title) {
  font-size: 14px;
  padding-block: 12px;
}

.stat_card:global(.ant-card-small)  :global(.ant-card-body) {
  padding-block: 12px;
  font-size: 1.5rem;
  text-align: center;
}