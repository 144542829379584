.login_container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.login_content {
  width: 300px;
}

.login_logo {
  display: flex;
  margin-bottom: 32px;
  align-items: center;
  justify-content: center;
}

.login_form_forgot {
  float: right;
}