.assign_form_content {
  padding: 16px 0;
}

.assign_form_buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.assign_form_buttons:last-child {
  margin-bottom: 0;
}