.wrapper {
  display: grid;
  grid-template-columns: 24px auto;
  gap: 2px;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
