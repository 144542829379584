.input {
  margin-bottom: 12px;
}

.input:last-child {
  margin-bottom: 0;
}

.input label {
  display: block;
  padding-bottom: 2px;

  line-height: 1.571;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}

.switch {
  display: flex;
  align-items: center;
}

.switch span {
  margin-left: 8px;
}

.anyStatus {
  background-color: #606060 !important;
}

.anyStatus:hover {
  background-color: #474747 !important;
}

.anyStatus :global(.ant-switch-handle) {
  inset-inline-start: calc(50% - 8px) !important;
}
