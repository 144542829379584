.header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  padding-top: 16px;
  padding-inline: 16px;
}

.content:last-child {
  padding-bottom: 16px;
}

.error {
  color: red;
}

.phoneLabel {
  padding-bottom: 10px;
}
