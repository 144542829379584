.pagination_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination_left {
  gap: 8px;
  display: flex;
  align-items: center;
}