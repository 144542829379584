.invitation__container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.invitation__content {
  width: 300px;
}

.invitation__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
