.tariffs_container {
  gap: 16px;
  display: flex;
  flex-direction: row;
}

.tariffs_item {
  flex-grow: 1;
}

.tariffs_item_actions {
  margin-top: 8px;
}