.wrapper {
  display: flex;
  gap: 4px;
  justify-content: flex-end;
  margin: 16px 0 0 0;
}

.border {
  border-top: thin solid #eee;
  padding: 16px 0 0 0;
}
