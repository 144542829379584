.charge_point_transaction__table table {
  table-layout: auto !important;
}

.charge_point_transaction__table td,
.charge_point_transaction__table th {
  white-space: pre;
  width: fit-content;
}

.charge_point_transaction__filter {
  display: flex;
  justify-content: space-between;
}

.charge_point_transaction__filter-reset {
  display: flex;
  gap: 8px;
}