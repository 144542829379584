.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.item {
  flex: 1 1 auto;
  max-width: calc((100% - 32px) / 2);
  width: 30%;
}

.input {
  margin-bottom: 8px;
}

.input :global label {
  color: rgba(0, 0, 0, 0.45) !important;
}

.action {
  position: absolute;
  top: -24px;
  right: 0px;
}

