.main-section {
  margin-bottom: 16px;
}

.main-section .ant-card-body {
  padding: 16px !important;
}

.element-mode-enter {
  opacity: 0;
}
.element-mode-enter-active {
  opacity: 1;
}
.element-mode-exit {
  opacity: 1;
  transform: translateX(0%);
}
.element-mode-exit-active {
  opacity: 0;
}
.element-mode-enter-active,
.element-mode-exit-active {
  transition: opacity 250ms;
}

.validity-period-row .ant-picker-range {
  max-width: 600px !important;
  width: 100%;
}

.validity-period-row .ant-form-item {
  margin-bottom: 0;
}

.row-with-label {
  margin-bottom: 16px;
}

.row-with-label .ant-form-item {
  margin-bottom: 0;
}

.label {
  margin-bottom: 4px;
}

.two-cols-row {
  margin-bottom: 16px;
}

.two-cols-row:last-child {
  margin-bottom: 0;
}

.two-cols-row .ant-col:first-child .ant-form-item-label {
  width: 70px;
}

.two-cols-row .ant-form-item {
  margin-bottom: 0;
}

.inline-form-item {
  margin-bottom: 16px;
}

.inline-form-item .ant-form-item-row {
  display: flex;
  align-items: center;
  flex-flow: row nowrap !important;
}

.inline-form-item .ant-form-item-label {
  padding-bottom: 0 !important;
  flex-shrink: 0;
}

.inline-form-item .ant-form-item-control {
  flex-grow: 1;
  width: auto !important;
}

.inline-form-item .ant-input-number-group-wrapper {
  display: block;
}

.range-inputs-row {
  margin-bottom: 16px;
}

.range-inputs-row .ant-form-item {
  width: 50%;
  margin-bottom: 0;
}

.range-inputs-row .ant-space-compact {
  width: 100%;
}

.range-inputs-row .ant-space-compact .ant-input-number-group-wrapper {
  width: calc(100% + 1px);
}

.range-inputs-row .ant-picker {
  width: 100%;
  max-width: 320px !important;
}

.range-inputs-row .ant-form-item:only-child {
  width: 100%;
}
