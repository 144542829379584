.register_container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.register_content {
  width: 300px;
}

.register_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}