.input {
  margin-bottom: 12px;
}

.input:last-child {
  margin-bottom: 0;
}

.input label {
  display: block;
  padding-bottom: 2px;

  line-height: 1.571;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
