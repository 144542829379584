.header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container {
  padding: 16px;
}

.card {
  position: relative;
  padding: 24px 16px;
  flex: 1 1 auto;
  width: 25%;
  min-width: 320px;

  border-radius: 8px;
  background-color: #fff;
  box-shadow: 1px 3px 7px 0px #dedddd;
}

.fullCard {
  width: 100%;
}

.action {
  position: absolute;
  top: 24px;
  right: 16px;
}